var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;
var viewportHeight = window.visualViewport.height;

$(document).ready(function() {
    window.addEventListener('resize', function () {
        if (windowWidth === window.innerWidth && windowHeight === window.innerHeight && viewportHeight === window.visualViewport.height) {
            return;
        }

        if (windowWidth !== window.innerWidth) {
            windowWidth = window.innerWidth;
        }

        if (windowHeight !== window.innerHeight || viewportHeight !== window.visualViewport.height) {
            windowHeight = window.innerHeight;
        }
    });

    $(window).on('scroll', function() {
        if (window.innerWidth > 767) {
            var sectionOffset = $('.social-pictures').offset().top;
            var scrollPosition = $(window).scrollTop() + $(window).height();
            
            if (scrollPosition > sectionOffset) {
                $('.first-col').addClass('show');
                
                setTimeout(function() {
                    $('.second-col').addClass('show');
                }, 300);
                
                setTimeout(function() {
                    $('.third-col').addClass('show');
                }, 600);
            }
        }
    });

    $(window).on('resize', function() {
        if (imageSwiper) {
            imageSwiper.destroy();
        }
        initBooksSlider();
    });
})

var imageSwiper;

function initBooksSlider() {
    if ($('#bookImagesSlider').length === 1 && $('#bookDescriptionSlider').length === 1) {
        imageSwiper = new Swiper("#bookImagesSlider", {
            effect: window.innerWidth <= 768 ? "slide" : "coverflow",
            grabCursor: true,
            centeredSlides: window.innerWidth > 768,
            slidesPerView: window.innerWidth <= 768 ? 1 : "auto",
            initialSlide: 0,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            slideToClickedSlide: true,
            breakpoints: {
                768: {
                    effect: "coverflow",
                    slidesPerView: "auto",
                    coverflowEffect: {
                        rotate: 0,
                        stretch: -30,
                        depth: 100,
                        modifier: 2,
                        slideShadows: false,
                    },
                },
                600: {
                    effect: "slide",
                    slidesPerView: 1,
                    centeredSlides: false,
                }
            }
        });

        var descriptionSwiper = new Swiper("#bookDescriptionSlider", {
            effect: "fade",
            slidesPerView: 1,
            initialSlide: 0,
            allowTouchMove: false,
        });

        imageSwiper.on("slideChange", function () {
            descriptionSwiper.slideTo(imageSwiper.activeIndex);
        });
    }
}

function svg(icon) {
    return '<span class="svg"><svg class="icon ' + icon + '"><use xlink:href="#' + icon + '" /></svg></span>';
}

window.initBooksSlider = initBooksSlider;